@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.logo {
  display: flex;
  gap: 6px;
  align-items: center;

  svg {
    flex: 0 0 auto;
    width: max-content !important;
    height: auto;
  }

  &_text{
    font-weight: 500;
  }
}
