@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.heading {
  &_mouse{
    animation: loopFrames .75s ease-out infinite;
  }
}

@keyframes loopFrames {
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
